/* after changing this file run 'npm run webpack:build' */

/***************************
put Sass variables here:
eg $input-color: red;
****************************/
// Override Bootstrap variables
@import 'bootstrap-variables';
// Import Bootstrap source files from node_modules
@import '~bootstrap/scss/bootstrap';
// @import '../css/highcharts-editor.min.css';

@import 'theme-material';

/* jhipster-needle-scss-add-vendor JHipster will add new css style */
// default override for mat-form-field (cf. mantis#177503 and note from Aymeric : mise à jour doit etre réaliser sur la totalité des inputs angular material)
@import 'mat-form-field-override';

//import custom eolng variables
@import 'eolng-variables';

.wrapper {
  // by default override any legacy .mat-mdc-form-field
  @include override-legacy-mat-form-field();
}

.mat-mdc-dialog-container.mdc-dialog {
  border-radius: 4px;

  .mat-mdc-dialog-title {
    padding: 8px;
    margin: -24px -24px 8px -24px;
    position: relative;
    color: $accent;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    border-bottom: solid 2px #cbcbcb;

    .mat-dialog-close-btn {
      position: absolute;
      top: 3px;
      right: 10px;

      fa-icon > svg {
        width: 10px;
      }
    }

    &.error-title {
      background-color: $danger;
      color: white;
    }
  }

  .mdc-dialog__title::before {
    display: none;
  }

  .mat-mdc-dialog-actions {
    justify-content: flex-end;
    padding: 8px 0;
    display: flex;
    flex-wrap: wrap;
    min-height: 52px;
    align-items: center;
    box-sizing: content-box;
    margin-bottom: -24px;
  }

  .mdc-dialog__content {
    display: block;
    margin: 0 -24px;
    padding: 0 24px;
    max-height: 65vh;
    overflow: auto;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
  }
}

.mat-mdc-form-field-appearance-outline {
  .mat-mdc-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-mdc-form-field-outline-end,
  .mat-mdc-form-field-outline-gap,
  .mat-mdc-form-field-outline-start {
    background-color: white;
  }

  .mat-mdc-form-field-infix {
    padding: 0.3em 0 1em 0;
  }
}

.mat-mdc-option .mdc-list-item__primary-text {
  display: inline-block;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-mdc-option.no-wrap .mdc-list-item__primary-text {
  white-space: nowrap;
}

/* When force-panel-under-field is set as the panelClass of a <mat-select>, the panel of this
select will open by default under the field and not above */
.mat-mdc-select-panel.force-panel-under-field {
  width: 100% !important;
  max-width: none !important;
  min-width: unset !important;
  margin-left: 30px;
  margin-top: 40px;

  mat-option {
    height: 27px;
    min-height: 27px;
    font-size: 13px;
    line-height: normal;
  }
}

.mat-autocomplete-panel.editable-select-dropdown {
  min-width: auto;
  margin-top: 0.6375em;

  .mat-option {
    font-size: 15px;
    padding: 0 6px;
  }
}

.mat-mdc-tab {
  height: 43px !important;
  color: $color-black !important;
}

.mdc-tab--active {
  opacity: 1 !important;
  font-weight: bold;
}

.mat-mdc-tooltip,
.mdc-tooltip__surface {
  font-size: 12px !important;
  background-color: $color-black !important;
  border-radius: 4px !important;
}

.mat-header-row {
  min-height: 40px;
  border-bottom-width: 0;

  .mat-header-cell {
    font-weight: bold;
    font-size: 16px;
  }
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: $default-brand;
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: $default-brand;
  font-weight: bold !important;
}

.mat-row {
  border-bottom-width: 0;
  min-height: 30px;
}

:root {
  --ck-color-mention-background: #{rgba($default-brand, 0.15)};
  --ck-color-mention-text: rgba(0, 0, 0, 0.87);
}

.ck.ck-toolbar {
  border: none !important;

  button {
    &:hover {
      background-color: $default-brand-hover;
    }

    // active
    &.ck-on {
      background-color: $default-brand !important;
      color: white !important;
    }
  }

  .ck.ck-toolbar__items > .ck.ck-button {
    // Aligne la tooltip du premier bouton sur la gauche pour ne pas qu'elle soit tronquée
    &:first-child .ck.ck-tooltip {
      left: 0;

      &__text {
        left: inherit;

        &::after {
          left: 10px;
        }
      }
    }

    // Aligne la tooltip du dernier bouton sur la droite pour ne pas qu'elle soit tronquée
    &:last-child .ck.ck-tooltip {
      left: inherit;
      right: 0;

      &__text {
        left: inherit;

        &::after {
          left: inherit;
          right: 0;
        }
      }
    }
  }
}

.ck.ck-dropdown,
.ck.ck-button {
  cursor: pointer;
}

.ck.ck-dropdown.tagging-button {
  & .ck-dropdown__arrow {
    display: none;
  }

  & .ck-dropdown__panel {
    & .ck.ck-icon :not([fill]):not(tspan) {
      fill: transparent;
    }

    & button {
      &:hover {
        background-color: $default-brand-hover;
      }

      & svg {
        height: 22px;
        width: 26px;
        margin: 8px 8px 8px 0;
      }
    }

    & rect[width='18'] {
      width: 18px;
      height: 19px;
    }

    & rect[width='10.5'] {
      width: 10.5px;
      height: 11.5px;
    }

    & rect[width='8'] {
      width: 8px;
      height: 1px;
    }
  }
}

// fix second dropdown position
.ck.ck-dropdown__panel.ck-dropdown__panel_ee.ck-dropdown__panel-visible {
  top: 0;
  bottom: unset;
}

.ck.ck-button.stylingDropDownBtn .ck.ck-button__label {
  width: 9em !important;
}

.ck.ck-reset.ck-dropdown__panel.ck-dropdown__panel_se.ck-dropdown__panel-visible .ck-dropdown__panel_se {
  left: 160px;
  top: 0;
  bottom: unset;
}

.ck.ck-button.stylingDropDown {
  height: 30px;
  margin: 0;
  cursor: pointer;

  &.category .ck.ck-button__label {
    width: 9em !important;
  }

  &.ck-off:hover {
    background-color: $default-brand-hover;
  }

  &.ck-on {
    background-color: $default-brand !important;
    color: white !important;

    .ck.ck-icon.ck-dropdown__arrow {
      path {
        fill: white !important;
      }
    }
  }

  &.addMoreButton {
    border-top: 1px solid $eolng-darkgrey !important;
  }

  .ck.ck-icon.ck-dropdown__arrow {
    height: 17px;
    width: 17px;

    path {
      fill: $eolng-darkgrey;
    }
  }
}

.copyFormatButton {
  border-top: 1px solid $eolng-darkgrey !important;
}

.confirmHideWarningZones {
  form {
    display: flex !important;
    padding: 16px !important;
    width: 350px !important;
    white-space: initial !important;
    background-color: #9b113f !important;

    .form-confirm-text {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      white-space: initial;

      .form-confirm-text-title {
        font-weight: bold;
        font-size: 16px;
        color: white;
        white-space: initial;
        line-height: normal;
      }

      .form-confirm-text-content {
        font-size: 16px;
        color: white;
        white-space: initial;
        line-height: normal;
        margin-top: 8px;
      }
    }

    .form-confirm-button {
      display: flex;
      flex: 0 0 90px;
      flex-direction: column;
      justify-content: space-between;

      .button-form-submit {
        background-color: white;
        cursor: pointer;

        .ck-button__label {
          font-size: 14px;
          color: $panel-header-color;
          display: initial;
        }
      }

      .button-form-cancel {
        background-color: inherit;
        cursor: pointer;

        &:hover {
          background-color: inherit;
        }

        .ck-button__label {
          font-size: 12px;
          display: initial;
          color: white;
          text-decoration: underline;
        }
      }
    }
  }
}
