@use 'sass:math';

@mixin override-legacy-mat-form-field(
  $font-size: 15px,
  $line-height: 1.125,
  $label-color: $default-brand,
  $label-as-placeholder-color: $grey,
  $value-disabled-color: lighten($grey, 10%),
  $label-allways-float: false
) {
  $line-spacing: math.div($line-height - 1, 2);
  $infix-margin-top: 1em * $line-height;
  $infix-padding: 0.7em - $line-spacing;
  $floating-label-transform: translateY(-$infix-margin-top - $infix-padding); // no scale to keep 13px size
  $label-font-size: $font-size * 0.87;

  .mat-mdc-form-field-appearance-legacy {
    font-size: $font-size;
    line-height: $line-height;

    .mat-mdc-form-field-infix {
      border-top-width: $infix-margin-top;
      padding: $infix-padding 0;

      mat-icon {
        width: $font-size;
        height: $font-size;
        vertical-align: text-top;
      }
      mat-label {
        font-size: $font-size;
      }
    }

    .mat-mdc-form-field-label {
      top: $infix-margin-top + $infix-padding;
      margin-bottom: 0;
      color: $label-as-placeholder-color;

      &-wrapper {
        top: -$infix-margin-top;
        padding-top: $infix-margin-top;
      }

      @if ($label-allways-float) {
        transform: $floating-label-transform;
        font-weight: bold;
        color: $label-color;
      }
    }

    &.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float {
      .mat-mdc-form-field-label {
        font-weight: bold;
        color: $label-color;
        transform: $floating-label-transform;
        font-size: $label-font-size;
        mat-label {
          font-size: $label-font-size;
        }
      }
    }
  }

  .mat-input-element:disabled,
  .mat-mdc-form-field-type-mat-native-select.mat-mdc-form-field-disabled .mat-mdc-form-field-infix::after,
  .mat-select-disabled .mat-select-value {
    color: $value-disabled-color;
  }
}
